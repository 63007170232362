import { Formik, FormikProps } from 'formik';

import { Autocomplete } from 'formik-mui';
// import Field from '../components/Field'
import Field from '../components/Field'
import Form from '../components/Form'
import ListItemField from '../components/ListItemField';
import React from 'react'
import { TextField } from '@mui/material'

const buttons = [{ label: 'Save', type: 'submit', color: 'primary' }]
const options =
  [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'Batman', year: 1994 },
    { title: 'BeetleJuice', year: 1994 },
    { title: 'Chud', year: 1994 }
  ]
export default function Test() {


  return (
    <div>
      Test

      <Form
        initialValues={{ name: null }}
        onChange={(v) => console.log('CHANGE', v)}

        buttons={buttons}
      >
        {/* <Field
          name="name"
          component={Autocomplete}
          options={options}
          getOptionLabel={(option) => option.title}
          style={{ width: 300 }}
          isOptionEqualToValue={
            (option, value) => {
              return option.title === value.title
            }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // We have to manually set the corresponding fields on the input component
              name="name"
              // error={touched['name'] && !!errors['name']}
              // helperText={errors['name']}
              label="Autocomplete"
              variant="outlined"
            />
          )}
        /> */}
        {/* <ListItemField
          fetchAll
          name='keywords'
        /> */}
        <Field name="description" type="html" />
        <Field name="day" type="simpleSelect" selectType='day' />
        <Field name="month" type="simpleSelect" />

        {/* <ListItemField
          fetchAll
          name='keywords'
          label='fetchAll'
        /> */}
      </Form>
    </div>
  )
}
